"use client";

import appInsights from "@portal/appInsights";
import { SessionProvider } from "next-auth/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "@portal/redux/store";

if (typeof window !== "undefined") {
  try {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  } catch (error) {
    console.error("failed loading app insights", error);
  }
}

const RootLayout = ({ children }: Readonly<{ children: React.ReactNode }>) => (
  <SessionProvider refetchOnWindowFocus={true} refetchInterval={8 * 60}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  </SessionProvider>
);

export default RootLayout;
